<!-- 疑问解答-- 列表 -->
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待解答" name="1">
        <Table :type="1" :active-name="activeName"/>
      </el-tab-pane>
      <el-tab-pane label="待反馈" name="2">
        <Table :type="2" :active-name="activeName"/>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="4">
        <Table :type="4" :active-name="activeName"/>
      </el-tab-pane>
      <el-tab-pane label="已关闭" name="5">
        <Table :type="5" :active-name="activeName"/>
      </el-tab-pane>
      <el-tab-pane label="全部" name="0">
        <Table :type="0" :active-name="activeName"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Table from "./components/Table";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {Table},
  data() {
    return {
      activeName: '1',
    }
  },
  created() {
    this.activeName = this.$route.query.status || '1'
    this.$store.commit('setPage', 1)
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    handleClick(tab, event) {
      this.$store.commit('setPage', 1)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  padding: 20rem 20rem 0;
}

::v-deep .el-tabs__content {
  overflow: visible !important;
}
.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
